require('./bootstrap');
require('./../../node_modules/jquery-ui/ui/effect.js')

require('summernote');
require('summernote/dist/summernote-bs4.js');
require('summernote/dist/lang/summernote-fr-FR.min.js');

import $ from 'jquery';
window.$ = window.jQuery = $;
//import 'jquery-ui/ui/effect.js';


$(document).ready(function($) {

/*
* VIDEO HOVER IMAGE
*/
$("#video-1").hover(
    function() {
        $(this).attr("src", '/img/savoir-faire/screen-video1-orgapred-Hover.png')
    }, function() {
        $(this).attr("src", '/img/savoir-faire/screen-video1-orgapred.png')
    }
);
$("#video-2").hover(
    function() {
        $(this).attr("src", '/img/savoir-faire/screen-video2-orgapred-Hover.png')
    }, function() {
        $(this).attr("src", '/img/savoir-faire/screen-video2-orgapred.png')
    }
);
$("#video-3").hover(
    function() {
        $(this).attr("src", '/img/savoir-faire/screen-video3-orgapred-Hover.png')
    }, function() {
        $(this).attr("src", '/img/savoir-faire/screen-video3-orgapred.png')
    }
);
$("#video-4").hover(
    function() {
        $(this).attr("src", '/img/savoir-faire/screen-video4-orgapred-Hover.png')
    }, function() {
        $(this).attr("src", '/img/savoir-faire/screen-video4-orgapred.png')
    }
);

/*
* VIDEO POPUP
*/
$('#modal-video').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget);
    var videoId = button.data('video');
    var response = '<video id="modal-video-reader" controls> \
            <source \
            id="modal-video-source" \
            src="/video/'+ videoId + '.mp4" \
            type="video/mp4"> \
            Sorry, your browser doesn\'t support embedded videos. \
        </video>';
    $('#modal-video .modal-video-message').html(response);
})

/*
* scroll to the top
*/
window.onscroll = function() {affBtnScrollTop()};

function affBtnScrollTop() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("btnScrollTop").style.display = "block";
    } else {
        document.getElementById("btnScrollTop").style.display = "none";
    }
}

$('#btnScrollTop').bind('click', function (event) {
    $('html, body').stop().animate({
        scrollTop: 0
    }, 1500, 'easeInOutExpo');
    event.preventDefault();
});

/*
* BACKOFFICE Editor
*/
$('.summernote').summernote({
    lang: 'fr-FR',
    placeholder: '...',
    tabsize: 2,
    height: 200,
    lang: 'fr-FR',
    callbacks: {
        onPaste: function (e) {
            if (document.queryCommandSupported("insertText")) {
                var text = $(e.currentTarget).html();
                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

                setTimeout(function () {
                    document.execCommand('insertText', false, bufferText);
                }, 10);
                e.preventDefault();
            } else { //IE
                var text = window.clipboardData.getData("text")
                if (trap) {
                    trap = false;
                } else {
                    trap = true;
                    setTimeout(function () {
                        document.execCommand('paste', false, text);
                    }, 10);
                    e.preventDefault();
                }
            }
        }
    },
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color','undo', 'redo']],
        ['para', ['ul', 'ol', 'paragraph', 'height']],
        ['misc', ['link','fullscreen', 'codeview']],
    ],
});

/*
* BACKOFFICE Suppression fichier
*/
$('#fileDestroy').on('show.bs.modal', function (event) {
    var modal = $(this);
    var button = $(event.relatedTarget);
    console.log(button);
    var type = button.data('type');
    var slug = button.data('slug');
    $.ajax({
        url: "/api/admin/file/"+type+"/"+slug,
        type: "delete",
    }).done(function(data){
        $('.site-image-destroy').hide();
        /*console.log(event.target);
        $(event.target).closest("div").hide();*/
        modal.find('.modal-response').text(data);
    }).fail(function(jqXHR, ajaxOptions, thrownError){
        modal.find('.modal-response').text('No response from server');
    });
});

});
